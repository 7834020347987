// @ts-ignore
import { useState } from "react";
import { FitToViewport } from "react-fit-to-viewport";
import { Button, Modal } from "semantic-ui-react";
// @ts-ignore
import { MongenRace } from "interfaces";
import { useSelector } from "react-redux";
import { RootState } from "reducer/store";

export const BeastPlanet = ({ claimStar, claimed_star }: any) => {
  const [openPopup, setPopup] = useState(false);
  const [text, setText] = useState('');
  const user = useSelector((state: RootState) => state.user);

  return (
    <div className="w-full h-full min-w-screen min-h-screen pt-8 flex justify-center bg-banner4 bg-contain transform-origin" id='beast-planet'>
      <Modal
        key="emailForm"
        open={openPopup}
        style={{ width: "unset" }}
        className="bg-popup bg-cover mini-scale"
      >
        <div
          className="max-w-[564px] h-[333px] w-screen"
        >
          <div className="max-w-[564px] h-[333px] w-screen pb-8">
            <div className="text-red w-[50px] md:w-[64px] cursor-pointer absolute float-right -right-2 -top-2"
              onClick={() => {
                setPopup(false);
              }}
            >
              <img src="/assets/images/footer/close.png" alt="close" className="" />
            </div>
            <div className="w-11/12 mx-auto flex h-full self-center">
              <div className="text-center text-white self-center justify-center font-cookie-bold text-4xl w-full">
                {text}
              </div>
            </div>
            <div className="w-full mx-auto self-center flex justify-center absolute bottom-[20px]">
              <Button
                color="blue"
                style={{
                  background: '#62FFFF',
                  width: '180px',
                  color: 'black',
                  borderRadius: '30px'
                }}
                className="w-[84px] bg-[#62FFFF]"
                onClick={async () => {
                  window.open('https://play.monsterra.io', '_blank')
                }}
              >
                <div className="text-xl font-bold font-cookie-run">Play Game</div>
              </Button>
            </div>
          </div>
        </div>
      </Modal >

      <FitToViewport
        width={1400}
        height={1100}
        minZoom={0.1}
        maxZoom={1}
        className="overflow-hidden"
      >
        <div className="text-4xl text-white font-cookie-run text-header-shadow text-center mt-4">
          CHECK-IN
        </div>
        <div className="text-6xl pt-2 font-cookie-bold text-white text-header-shadow text-center">
          BEAST PLANET
        </div>
        <div className="relative w-full">
          <div className="flex justify-center mt-[40px]">
            <img src='/assets/images/beast-planet/background.png'
              className="w-[1343px] h-[790px] mx-auto" alt="elipse"
            />
            <div className='w-[1167px] h-[658px] overflow-hidden absolute top-[70px] rounded-xl'>
              <img src='/assets/images/beast-planet/banner.png'
                className="w-[1167px] h-[658px] mx-auto beast-anim" alt="elipse"
              />
            </div>
          </div>
          {/* <img src='/assets/images/beast-planet/question.png'
            onClick={() => {
              setPopup(true)
            }}
            className="w-[118px] h-[118px] absolute top-[0px] -translate-y-[29px] left-[30px] cursor-pointer" alt="elipse"
          /> */}
          <div className="flex -translate-y-[100px]">
            {!claimed_star?.includes(MongenRace.Beast) && <img src='/assets/images/beast-planet/claim_btn.png'
              className={`cursor-pointer mx-auto`} alt="elipse"
              onClick={async () => {
                if (!user?.isJoinnedClan) {
                  setPopup(true)
                  setText('You need to join a clan first.')
                } else if (!user?.isClanJoinTournament) {
                  setPopup(true)
                  setText('Your clan owner needs to register for the tournament')
                } else {
                  await claimStar(MongenRace.Beast)
                }
              }}
            />}
          </div>
        </div>
      </ FitToViewport>
      {/* <img src='/assets/images/banner/divider.png' alt='divider' className="w-full h-[80px] z-99 absolute top-0 -translate-y-[40%]" /> */}
    </div>
  )
}