// @ts-ignore
import { FitToViewport } from "react-fit-to-viewport";
// @ts-ignore

export const EndingSection = () => {
    // Page 8
    return (
      < div className="relative  w-full h-full min-w-screen min-h-screen pt-8 flex justify-center bg-contain bg-banner10" >
        <FitToViewport
          width={1400}
          height={1100}
          minZoom={0.1}
          maxZoom={2}
          className="overflow-hidden ending"
        >
          <div className="text-6xl pt-10 font-cookie-bold text-white text-header-shadow text-center">
            Celebrating one year of brilliance since our Mainnet launch on August 25, 2022!
          </div>
          <div className="text-3xl mt-8 font-cookie text-white text-header-shadow text-center">
            <div>
              It’s been a year of fierce growth, incredible teamwork, and
            </div>
            <div>
              unwavering support from partners. We couldn't have
            </div>
            <div>
              reached this milestone without your belief in us!
            </div>
          </div>
          <div className="flex justify-center gap-4 mt-20 mx-auto">
            <div className="w-[320px] h-[200px] rounded-2xl">
              <div className="item-ending flex self-center justify-center flex-col gap-4 w-full h-full px-4">
                <div className="font-cookie-run font-bold text-white text-5xl text-center text-ending-shadow">100+</div>
                <div className="font-cookie-run text-white text-xl text-center">VCs & partners</div>
              </div>
            </div>
            <div className="w-[320px] h-[200px] rounded-2xl">
              <div className="item-ending flex self-center justify-center flex-col gap-4 w-full h-full px-4">
                <div className="font-cookie-run font-bold text-white text-5xl text-center text-ending-shadow">450K+</div>
                <div className="font-cookie-run text-white text-xl text-center">Players</div>
              </div>
            </div>
            <div className="w-[320px] h-[200px] rounded-2xl">
              <div className="item-ending flex self-center justify-center flex-col gap-4 w-full h-full px-4">
                <div className="font-cookie-run font-bold text-white text-5xl text-center text-ending-shadow">200K+</div>
                <div className="font-cookie-run text-white text-xl text-center">Community members</div>
              </div>
            </div>
            <div className="w-[320px] h-[200px] rounded-2xl">
              <div className="item-ending flex self-center justify-center flex-col gap-4 w-full h-full px-4">
                <div className="font-cookie-run font-bold text-white text-5xl text-center text-ending-shadow">50+</div>
                <div className="font-cookie-run text-white text-xl text-center">Team members</div>
              </div>
            </div>
          </div>
  
          <div className="text-3xl mt-10 font-cookie-bold text-white text-header-shadow text-center pb-32">
            <div>Here's to many more years of growth, achievements, and gaming excellence! </div>
            <div>Let's create history together!</div>
          </div>
        </ FitToViewport>
        <img src='/assets/images/footer/balloon-left.png' className='left-[0px] h-[80%] bottom-[0px] absolute balloon'
          style={{
            zIndex: -1
          }}
          alt='balloon' />
        <img src='/assets/images/footer/balloon-right.png' className='right-[0px] h-[80%] bottom-[0px] absolute balloon'
          style={{
            zIndex: -1
          }}
          alt='balloon' />
        {/* <Tutorial /> */}
        <img src='/assets/images/logo.png' className='hidden md:block absolute right-[200px] w-[13vw] bottom-[40px] cursor-pointer' onClick={() => window.open('https://monsterra.io', '_blank')} alt='balloon' />
        <img src='/assets/images/banner/mongen-join.png' className=' absolute left-0 bottom-0 w-[27vw] cursor-pointer' alt='mongen' />
        {/* <img src='/assets/images/banner/divider.png' alt='divider' className="w-full h-[80px] z-99 absolute top-0 -translate-y-[40%]" /> */}
      </div >
    )
  }
  