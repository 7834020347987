import clsx from 'clsx';
import { useEffect, useState } from 'react';

type InputProps = {
    fieldName: string,
    value: string,
    setValue: any,
    className?: string,
    submit?: any,
    setErr?: any,
    disabled?: boolean,
    validation?: (data?: any) => string,
} & React.ComponentPropsWithoutRef<'input'>;

export const TextInput = ({
    fieldName,
    value,
    setValue,
    className,
    validation,
    type,
    submit,
    setErr,
    disabled,
    ...props
}: InputProps) => {
    const [error, setError] = useState("");

    const validateEmail = (email: string) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    }

    const validateUsername = (username: string) => {
        return username.match(
            /^[A-Za-z0-9]{4,16}$/
        );
    }

    const validateField = (data: any) => {
        if (data.length <= 0) {
            setError(`Field mustn't empty`);
        } else if (validation) {
            setError(validation(data));
        } else if (type === "email") {
            setError(!validateEmail(data) && "Invalid email address !");
        } else if (type === "username") {
            setError(!validateUsername(data) && "User name must be between 4 and 16 characters long and cannot contain spaces or special characters.");
        } else {
            setError("");
        }
    }

    useEffect(() => {
        setErr && setErr(error);
    }, [error])

    return (
        <div className={className}>
            <div className={clsx("text-center text-white flex mx-auto justify-center w-full overflow-hidden rounded-lg relative", !error && "my-3")}>
                {fieldName && <div className="flex absolute inset-y-0 left-0 pl-3 pointer-events-none flex-col">
                    <span className="text-gray text-sm mt-1">{fieldName}</span>
                </div>}
                <input
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            submit();
                        }
                    }}
                    {...props}
                    type={type ?? 'text'}
                    className={
                        `text-md block w-full h-[48px] pl-3 pr-3 text-black font-medium border-t-3
                        ${disabled ? "border-gray-2" : "border-orange"}
                        ${disabled && 'text-input-disabled'}
                        ${fieldName && "pt-5"}`
                    }
                    disabled={disabled}
                    value={value}
                    onChange={(e: any) => {
                        validateField(e.target.value);
                        setValue(e.target.value)
                    }}
                />
            </div>
            {error && <div className="text-red text-left py-2">
                {error}
            </div>}
        </div>
    )
}