import { LoginType, SSO_TYPE, Rarity } from "interfaces";
import { store } from "reducer/store";

export const SSO_META = () => {
  let SSO_META: SSO_TYPE = store.getState().ssometa.meta;
  return SSO_META;
}

export const SSO_CONTRACT = () => store.getState().ssometa.contract;

export const MENU_AUCTION_B2C = [
  {
    name: "Open Auctions",
    api: "/auction-items/open-items",
    title_current_price: "Current Bid: ",
    title_next_price: "Next minimum Bid: ",
  },
  {
    name: "My Bids",
    api: "/auction-items/bidding-items",
    title_current_price: "Your Bid: ",
    title_next_price: "Out bid: ",
  },
  {
    name: "My Payouts",
    api: "/auction-items/payout-items",
    title_current_price: "You earned: ",
    title_next_price: "You earn: ",
  },
  {
    name: "My Winning Bids",
    api: "/auction-items/winning-items",
    title_current_price: "Your Bid: ",
    title_next_price: null,
  }
]

export const MENU_AUCTION_P2P = [
  {
    name: "Open Auctions",
    api: ["/p2p-auction/open-items", "/p2p-auction/coming-items"],
    title_current_price: "Current Bid: ",
    title_next_price: "Next minimum Bid: ",
  },
  {
    name: "My Auctions",
    api: "/p2p-auction/open-items-owner",
    title_current_price: "Current Bid: ",
    title_next_price: null,
  },
  {
    name: "My Bids",
    api: "/p2p-auction/bidding-items",
    title_current_price: "Your Bid: ",
    title_next_price: "Out bid: ",
  },
  // {
  //   name: "My Payouts",
  //   api: "/auction-items/payout-items",
  //   title_current_price: "You earned: ",
  //   title_next_price: "You earn: ",
  // },
  {
    name: "My Winning Bids",
    api: "/p2p-auction/winning-items",
    title_current_price: "Your Bid: ",
    title_next_price: null,
  },
  {
    name: "My Inventory",
    api: "/p2p-auction/inventory",
    title_current_price: null,
    title_next_price: null,
  }
]

export const DEFAULT_ITEMS_PER_PAGE = 10

export const EVENT_SOCKET = [
  "auction/new-item",
  "auction/bid"
]

export const AUCTION_TYPE = {
  auctionP2P: "auction-p2p",
  auctionB2C: "auction-b2c"
}

export const OPTIONS_RARITY = [
  {
    key: Rarity.All,
    value: Rarity.All,
    text: "All",
  },
  {
    key: Rarity.Common,
    value: Rarity.Common,
    text: "Common",
    disabled: true

  },
  {
    key: Rarity.Uncommon,
    value: Rarity.Uncommon,
    text: "Uncommon",
    disabled: true
  },
  {
    key: Rarity.Rare,
    value: Rarity.Rare,
    text: "Rare",
    disabled: true

  },
  {
    key: Rarity.Epic,
    value: Rarity.Epic,
    text: "Epic",

  },
  {
    key: Rarity.Legendary,
    value: Rarity.Legendary,
    text: "Legendary",

  },
]

export const getDiscordAuthorizeUrl = (discordConfig: any) => {
  const authorizeDiscordUrl = `https://discord.com/api/oauth2/authorize?client_id=${discordConfig.clientId}&redirect_uri=${encodeURIComponent(discordConfig.redirectUri)}&response_type=code&scope=${encodeURIComponent(discordConfig.scope)}`
  return authorizeDiscordUrl;
}

const Config = {
  ApiHost: process.env.REACT_APP_API_HOST,
  EventApiHost: process.env.REACT_APP_EVENT_HOST || "wss://event-celest.monsterra.io",
  AuctionApiHost: process.env.REACT_APP_AUCTION_HOST,
  SupportedAuth: [LoginType.BSC, LoginType.Terra, LoginType.Avalanche, LoginType.Okex, LoginType.Email, LoginType.Facebook, LoginType.Google],
  AntiWalletAuth: [LoginType.Email, LoginType.Facebook, LoginType.Google, LoginType.Apple],

  FormattedTime: {
    // weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  },
};
export default Config;