// @ts-ignore
import { useMemo, useState } from "react";
import { FitToViewport } from "react-fit-to-viewport";
import { Modal } from "semantic-ui-react";
// @ts-ignore
import { RewardsDisplay } from "components/rewards";
import { HappyBirthdayResource, RewardType } from "interfaces";
import GameConstant from "interfaces/game-constants";
import { useSelector } from "react-redux";
import { RootState } from "reducer/store";
import { api, ui } from "services";
import { Character } from "home";
import { useTranslation } from "react-i18next";

export const ChaosPlanet = ({ reward_list, claimed_quest, updateQuest, ticketQuantity, characterList }: any) => {
    const [spinning, setSpinning] = useState(false);
    const [openExchange, setOpenExchange] = useState(false);
    const user = useSelector((state: RootState) => state.user);
    const [rewardList, setRewardList] = useState(reward_list)
    const [currentIdx, setCurrentIdx] = useState(0)
    const {t} = useTranslation();
  
    const spin = async () => {
      try {
        if (spinning) return;
        let { reward, customerQuest } = await api.postEventDeal('/birthday/spin-chaos', {});
  
        setSpinning(true)
        let rewardDegree = (6 - reward.idx) * 60;
        const rotation = rewardDegree + 3600;
  
        const wheel = document.querySelector(".wheel-anim");
        console.log(rotation)
  
        // @ts-ignore
        wheel.style.transition = "transform 5s cubic-bezier(0.25, 0.46, 0.45, 0.94)";
        // @ts-ignore
        wheel.style.transform = `rotate(${rotation}deg)`;
  
        setTimeout(() => {
          // @ts-ignore
          wheel.style.transition = null;
          // @ts-ignore
          wheel.style.transform = `rotate(${rewardDegree}deg)`;
        }, 6000); // Wait for the rotation animation to complete
  
        setTimeout(() => {
          updateQuest && updateQuest(customerQuest);
          ui.openRewardPopup([reward])
          setCurrentIdx(reward.idx)
          setSpinning(false);
        }, 7000)
      } catch (err: any) {
        ui.alertFailed(t(err?.message), "")
        setSpinning(false)
      }
    };
  
    const exchangeSpin = async () => {
      try {
        const res = await api.postEventDeal('/birthday/exchange-spin-turn', {})
        updateQuest && updateQuest(res);
        ui.openRewardPopup([{ reward_quantity: 1, reward_type: RewardType.SPIN_TURN }])
      } catch (err: any) {
        ui.alertFailed(t(err?.message), "")
      }
    }
  
    const isDisabled = (text: string, index: number) => {
      // @ts-ignore
      let totalCharacter = characterList[HappyBirthdayResource[text]];
      if (['H', 'A', 'P', 'Y'].includes(text)) {
        let lastIndex = 'HAPPY BIRTHDAY'.lastIndexOf(text);
        if (index < lastIndex) {
          // @ts-ignore
          return !totalCharacter;
        } else {
          return totalCharacter < 2 || !totalCharacter;
        }
      }
  
      // @ts-ignore
      return !totalCharacter
    }
  
    const checkCanExchange = () => {
      let disabledList = "HAPPY BIRTHDAY".split('').map((i, index) => {
        if (i === ' ') {
          return false;
        }
        return isDisabled(i, index)
      }).filter((i) => i !== false)
  
      return !disabledList.length;
    }
  
    useMemo(() => {
      if (!user?.id) {
        setRewardList(GameConstant.BIRTHDAY_SPIN_REWARDS)
      } else {
        setRewardList(reward_list)
        const wheel = document.querySelector(".wheel-anim");
        if (wheel) {
          let rewardDegree = (6 - currentIdx) * 60;
          //@ts-ignore
          wheel.style.transition = null;
          // @ts-ignore
          wheel.style.transform = `rotate(${rewardDegree}deg)`;
        }
      }
    }, [user?.id, reward_list, currentIdx])
  
    return (
      <div className="transform-origin w-full h-full min-w-screen min-h-screen pt-8 flex justify-center bg-banner7 bg-contain" id='chaos-planet'>
        <Modal
          key="emailForm"
          open={!!openExchange}
          style={{ width: "unset" }}
          className="bg-popup"
        >
          <div
            className="w-full"
          >
            <div className="w-[70vw] h-[41.25vw] bg-contain bg-no-repeat pb-8">
              <div className="text-red cursor-pointer absolute float-right -right-2 -top-2"
                onClick={() => {
                  setOpenExchange(false);
                }}
              >
                <img src="/assets/images/footer/close.png" alt="close" className="w-full max-w-[7vw]" />
              </div>
              <div className="pt-[6vw]">
                <div className="flex gap-[0.5vw] self-center justify-center">
                  {"HAPPY BIRTHDAY".split('').map((i, index) => {
                    if (i === ' ') {
                      return <div className="w-[2vw]"></div>
                    }
                    return (
                      // @ts-ignore
                      <Character text={i} width="3.5vw" textSize="text-[2.2vw]" disable={isDisabled(i, index)} />
                    )
                  })}
                </div>
                <div className="px-[12.5%] mt-[3%]">
                  <div className="text-white font-cookie-run font-bold text-[2vw]">Your inventory</div>
                  <div className='bg-[#FFFFFF] bg-opacity-20 w-full h-[18vw] mt-[1.5vw] rounded-lg flex gap-[0.5vw] flex-wrap p-[1vw] overflow-y-scroll'>
                    {Object.keys(characterList).map((key: string) => {
                      let character = HappyBirthdayResource[Number(key)];
                      let amount = characterList[key];
                      return (
                        <>
                          {Array.from({ length: amount }).map((i) => {
                            return (
                              <Character text={character} width="3.5vw" textSize="text-[2.2vw]" />
                            )
                          })}
                        </>
                      )
                    })}
                  </div>
                  <div className="relative cursor-pointer mt-[1.5vw]"  onClick={async () => {
                      if (checkCanExchange()) {
                        ui.confirmActionBirthday('Confirmation', 'This action will burn the Characters you have selected. Are you sure you want to burn Characters in exchange for spins?', '',
                          async () => {
                            await exchangeSpin()
                          }
                        )
                      }
                    }}>
                    <img src='/assets/images/chaos-planet/exchange_btn.png' alt='btn' className={`${!checkCanExchange() && 'grayscale-[100]'}  w-[11vw] mx-auto`} />
                    <div className={`absolute text-[#003380] top-[50%] -translate-y-[50%] text-[1.5vw] font-cookie-run font-bold text-center w-full`}>
                      Exchange
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-center items-center mx-auto bottom-10 absolute justify-center w-full">
              </div>
            </div>
          </div>
        </Modal >
  
        <FitToViewport
          width={1450}
          height={1000}
          minZoom={0.1}
          maxZoom={1}
          className="overflow-hidden"
        >
          <div className="text-4xl text-white font-cookie-run text-header-shadow text-center mt-4">
            CHECK-IN
          </div>
          <div className="text-6xl pt-2 font-cookie-bold text-white text-header-shadow text-center">
            CHAOS PLANET
          </div>
          <div className="w-full mt-[90px] relative" style={{
            zIndex: 'inherit'
          }}>
            <div className="absolute w-[680px] h-[680px] left-[385px]">
              <img src='/assets/images/chaos-planet/6.png' alt='spin' className="absolute w-[680px] h-[680px]" style={{
                zIndex: 1
              }} />
              <img src='/assets/images/chaos-planet/1.png' alt='indicator' className="w-[200px] absolute text-center left-[50%] -translate-y-[25%] -translate-x-[50%]" style={{
                zIndex: 8
              }} />
              <img
                src='/assets/images/chaos-planet/2.png' alt='center' className="absolute w-[240px] left-[220px] top-[220px]" style={{
                  zIndex: 8,
                }} />
              <div className="wheel-container" key={JSON.stringify(rewardList)} style={{
                zIndex: 7
              }}>
                <div className="wheel-anim">
                  <div className="wheel">
                    <div className="segment-container">
                      {rewardList.map((rewardInfo: any, idx: number) => {
                        return (
                          <div className={`segment ${claimed_quest?.includes(idx) && "grayscale-[100]"}`}>
                            <img src="/assets/images/chaos-planet/3.png" alt='segment' className={`absolute ${idx % 2 === 1 && 'blue-segment'}`} />
                            {rewardInfo && <div className="absolute font-cookie-run font-bold text-xl segment-reward">
                              <RewardsDisplay reward={rewardInfo} />
                            </div>}
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div>
  
              <div className="absolute top-[640px] w-full" style={{
                zIndex: 9
              }}>
                <div className="flex justify-center gap-20">
                  <div className="relative cursor-pointer" onClick={() => {
                      setOpenExchange(true)
                    }}>
                    <img src='/assets/images/chaos-planet/exchange_btn.png' alt='btn' className="w-[240px]" />
                    <div className="absolute text-[#003380] top-[50%] -translate-y-[50%] text-3xl font-cookie-run font-bold text-center w-full" >
                      Exchange
                    </div>
                  </div>
                  <div className="relative cursor-pointer" onClick={() => {
                    if (ticketQuantity && !spinning) {
                      spin()
                    }
                  }}>
                    <img src='/assets/images/chaos-planet/spin_btn.png' alt='btn' className={`w-[240px] ${(!ticketQuantity || spinning) && 'grayscale-[100]'}`} />
                    <div className="absolute text-[#B84E00] top-[50%] -translate-y-[50%] text-3xl font-cookie-run font-bold text-center w-full flex gap-2 justify-center">
                      <div className="justify-center self-center">Spin</div>
                      <div className="justify-center self-center"><img src='/assets/images/chaos-planet/ticket.png' className="w-[32px] h-[32px]" alt='ticket' /></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
  
        </ FitToViewport>
        {/* <img src='/assets/images/banner/divider.png' alt='divider' className="w-full h-[80px] z-99 absolute top-0 -translate-y-[40%]" /> */}
      </div >
  
    )
  }