import { EnumEntity, FormEntity, GridEntity } from "../interfaces";
import api from "./api";
let grids: { [key: string]: GridEntity } = {};
let forms: { [key: string]: FormEntity } = {};
let enums: { [key: string]: EnumEntity } = {};
let isSetMeta: boolean = false;
let meta: any = {};
function setMeta(
  _grids: { [key: string]: GridEntity },
  _forms: { [key: string]: FormEntity },
  _enums: { [key: string]: EnumEntity }
): void {
  isSetMeta = true;
  grids = _grids;
  forms = _forms;
  enums = _enums;
}
function getAllEnums(): { [key: string]: EnumEntity } {
  return enums;
}
function getEnum(name: string): EnumEntity {
  return enums[name];
}

function getGrid(name: string): GridEntity {
  return grids[name];
}

function getFormByName(name: string): FormEntity {
  return forms[name];
}
function checkIsSetMeta(): boolean {
  return isSetMeta;
}
async function loadMeta() {
  let rs = await api.get("/customer/get-SSO-meta");
  meta = rs;
}
function getMeta(): any {
  return meta;
}
const dataServices = {
  loadMeta,
  getMeta,
  getGrid,
  getFormByName,
  getEnum,
  checkIsSetMeta,
  setMeta,
  getAllEnums,
};
export default dataServices;
