import HomePage from "home";
import { Route, Routes } from "react-router-dom";
import ScrollToTop from "./scrollToTop";

export default function Main() {
  return (
    <div>
      <div className={``}>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<HomePage />} />
          </Routes>
        </ScrollToTop>
      </div>
    </div>
  );
}
