import dayjs from "dayjs";
import {
  BarrackType,
  BodyPart,
  BombardType,
  CampType,
  MongenRace,
  MongenStatType,
  NFTType,
  PlotType,
  Rarity,
  RewardEntity,
  RewardType,
  Skill,
  SkillType,
  TicketRarity,
  TowerType,
  TrapType,
} from "interfaces";
import GameConstant from "interfaces/game-constants";
import Troops from "interfaces/troop.json";
import skill from "../services/skill";
import { t } from "i18next";
import _ from "lodash";

function formatHostUrl(hostapi: string) {
  if (hostapi[hostapi.length - 1] === "/") {
    return hostapi.slice(0, -1);
  }
  return hostapi;
}
export const toHex = (num: any): string => {
  const val = Number(num);
  return "0x" + val.toString(16);
};
function getRarityIcon(rarity: Rarity) {
  const baseUrl = "/assets/images/rarity";
  switch (rarity) {
    case Rarity.Common:
      return baseUrl + "/common-icon.svg";
    case Rarity.Uncommon:
      return baseUrl + "/uncommon-icon.svg";
    case Rarity.Rare:
      return baseUrl + "/rare-icon.svg";
    case Rarity.Epic:
      return baseUrl + "/epic-icon.svg";
    case Rarity.Legendary:
      return baseUrl + "/legendary-icon.svg";
    default:
      return null;
  }
}

const getCountdownText = (date: any) => {
  if (date?.["$numberDecimal"]) {
    date = date["$numberDecimal"];
  }
  if (!date) {
    return null;
  }
  let diff = dayjs(date).diff(dayjs(), "second");
  if (diff <= 0 || dayjs(date).isBefore(dayjs())) {
    return null;
  }
  if (dayjs(date).isBefore(dayjs())) {
    return {
      days: "0",
      hours: "0",
      minutes: "0",
      seconds: "0",
    };
  }
  let day = Math.floor(diff / 86400) + "";
  let hour = Math.floor((diff % 86400) / 3600) + "";
  let min = Math.floor((diff % 3600) / 60) + "";
  let second = (diff % 60) + "";

  return {
    days: day.padStart(2, "0"),
    hours: hour.padStart(2, "0"),
    minutes: min.padStart(2, "0"),
    seconds: second.padStart(2, "0"),
  };
};

const getCountdownTextBySeconds = (diff: any) => {
  if (diff <= 0) {
    return null;
  }
  let hour = Math.floor(diff / 3600) + "";
  let min = Math.floor((diff % 3600) / 60) + "";
  let second = (diff % 60) + "";
  return {
    hours: hour.padStart(2, "0"),
    minutes: min.padStart(2, "0"),
    seconds: second.padStart(2, "0"),
  };
};

function getTimeDisplay(secDif: number) {
  if (secDif < 0) {
    return `0 days 0 hours 0 mins 0 secs`;
  }
  const days = Math.floor(secDif / (24 * 60 * 60));
  secDif = secDif - days * 24 * 60 * 60;
  const hours = Math.floor(secDif / 60 / 60);
  secDif = secDif - hours * 60 * 60;
  const min = Math.floor(secDif / 60);
  secDif = secDif - min * 60;
  const sec = Math.floor(secDif);
  return `${days} days ${hours} hours ${min} mins ${sec} secs`;
}

function convertToInternationalCurrencySystem(labelValue: number, digits = 2) {
  if (!labelValue && labelValue != 0) return null;
  let value = Math.abs(Number(Number(labelValue).toFixed(13)));

  let digitValue = 100;
  switch (digits) {
    case 2:
      digitValue = 1.0e2;
      break;
    case 3:
      digitValue = 1.0e3;
      break;
    case 4:
      digitValue = 1.0e4;
      break;
    case 5:
      digitValue = 1.0e5;
      break;
    case 6:
      digitValue = 1.0e6;
      break;
    case 7:
      digitValue = 1.0e7;
      break;
    case 8:
      digitValue = 1.0e8;
      break;
  }

  if (value >= 1.0e9) {
    value = value / 1.0e9;
    value = Math.floor(Number(value * digitValue)) / digitValue;
    value = toFixed(value);
    return value + "B";
  } else if (value >= 1.0e6) {
    value = value / 1.0e6;
    value = Math.floor(Number(value * digitValue)) / digitValue;
    value = toFixed(value);
    return value + "M";
  } else if (value >= 1.0e3) {
    value = value / 1.0e3;
    value = Math.floor(Number(value * digitValue)) / digitValue;
    value = toFixed(value);
    return value + "K";
  } else {
    value = value / 1;
    value = Math.floor(Number(value * digitValue)) / digitValue;
    value = toFixed(value);
    return value;
  }

  function toFixed(x: any) {
    if (Math.abs(x) < 1.0) {
      var e = parseInt(x.toString().split("e-")[1]);
      if (e) {
        x *= Math.pow(10, e - 1);
        x = "0." + new Array(e).join("0") + x.toString().substring(2);
      }
    } else {
      var e = parseInt(x.toString().split("+")[1]);
      if (e > 20) {
        e -= 20;
        x /= Math.pow(10, e);
        x += new Array(e + 1).join("0");
      }
    }
    return x;
  }
  // // Nine Zeroes for Billions
  // return Math.abs(Number(labelValue)) >= 1.0e9
  //   ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + "B"
  //   : // Six Zeroes for Millions
  //   Math.abs(Number(labelValue)) >= 1.0e6
  //   ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + "M"
  //   : // Three Zeroes for Thousands
  //   Math.abs(Number(labelValue)) >= 1.0e3
  //   ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2) + "K"
  //   : Math.abs(Number(labelValue)).toFixed(2);
}
function randomInt(min: number, max: number): number {
  return Math.round(min + Math.random() * (max - min));
}

function getMongenStat(
  dna: number[][],
  level: number,
  evolution: number,
  morale_eff_value = 1
) {
  const bodyPartsStat: number[][] = [
    GameConstant.BaseFormStat.map(
      (i: number[], statIndex: number) =>
        i[dna[BodyPart.Form][0]] *
        GameConstant.FormRarityBaseMultiply[statIndex][dna[BodyPart.Form][1]]
    ),
    GameConstant.BaseBodyPartStat.map(
      (i: number[], statIndex: number) =>
        i[dna[BodyPart.Head][0]] *
        GameConstant.BodyPartRarityBaseMultiply[statIndex][
          dna[BodyPart.Head][1]
        ]
    ),
    GameConstant.BaseBodyPartStat.map(
      (i: number[], statIndex: number) =>
        i[dna[BodyPart.Eyes][0]] *
        GameConstant.BodyPartRarityBaseMultiply[statIndex][
          dna[BodyPart.Eyes][1]
        ]
    ),
    GameConstant.BaseBodyPartStat.map(
      (i: number[], statIndex: number) =>
        i[dna[BodyPart.Horns][0]] *
        GameConstant.BodyPartRarityBaseMultiply[statIndex][
          dna[BodyPart.Horns][1]
        ]
    ),
    GameConstant.BaseBodyPartStat.map(
      (i: number[], statIndex: number) =>
        i[dna[BodyPart.Tail][0]] *
        GameConstant.BodyPartRarityBaseMultiply[statIndex][
          dna[BodyPart.Tail][1]
        ]
    ),
    GameConstant.BaseBodyPartStat.map(
      (i: number[], statIndex: number) =>
        i[dna[BodyPart.Back][0]] *
        GameConstant.BodyPartRarityBaseMultiply[statIndex][
          dna[BodyPart.Back][1]
        ]
    ),
  ];
  let baseStat = bodyPartsStat.reduce((a: number[], b: number[]) => {
    let tmp = [];
    for (var i = 0; i < b.length; i++) {
      tmp.push((a[i] || 0) + b[i]);
    }
    return tmp;
  }, []);
  //calculate max stat

  // baseStat = baseStat.map((i) => Math.round(i));
  let race = dna[BodyPart.Form][0];
  let currentStat: number[] = [
    //health
    baseStat[MongenStatType.Health] * 5 +
      150 +
      20 *
        baseStat[MongenStatType.Health] *
        (GameConstant.LevelStatMultiply[MongenStatType.Health][race] / 100) *
        (level + evolution - 2) *
        10,
    //sturdiness
    baseStat[MongenStatType.Sturdiness] *
      (1 +
        4 *
          (GameConstant.LevelStatMultiply[MongenStatType.Sturdiness][race] /
            100) *
          (level + evolution - 2)),
    //magic
    baseStat[MongenStatType.Magic] *
      (1 +
        6 *
          (GameConstant.LevelStatMultiply[MongenStatType.Magic][race] / 100) *
          (level + evolution - 2)),
    //aggresion
    baseStat[MongenStatType.Aggresion] *
      (1 +
        3 *
          (GameConstant.LevelStatMultiply[MongenStatType.Aggresion][race] /
            100) *
          (level + evolution - 2)),
  ];

  currentStat = currentStat.map((stat) => stat * morale_eff_value);
  baseStat = baseStat.map((i: number) => Math.round(i));
  currentStat = currentStat.map((i: number) => Math.round(i));
  return currentStat;
}

function getFullMongenStat(
  dna: number[][],
  level: number,
  evolution: number,
  morale_eff_value = 1
): {
  base: number[];
  max: number[];
  ultimateMax: number[];
  rarity: number;
  basePower: number;
  currentPower: number;
  maxPower: number;
} {
  const bodyPartsStat: number[][] = [
    GameConstant.BaseFormStat.map(
      (i: number[], statIndex: number) =>
        i[dna[BodyPart.Form][0]] *
        GameConstant.FormRarityBaseMultiply[statIndex][dna[BodyPart.Form][1]]
    ),
    GameConstant.BaseBodyPartStat.map(
      (i: number[], statIndex: number) =>
        i[dna[BodyPart.Head][0]] *
        GameConstant.BodyPartRarityBaseMultiply[statIndex][
          dna[BodyPart.Head][1]
        ]
    ),
    GameConstant.BaseBodyPartStat.map(
      (i: number[], statIndex: number) =>
        i[dna[BodyPart.Eyes][0]] *
        GameConstant.BodyPartRarityBaseMultiply[statIndex][
          dna[BodyPart.Eyes][1]
        ]
    ),
    GameConstant.BaseBodyPartStat.map(
      (i: number[], statIndex: number) =>
        i[dna[BodyPart.Horns][0]] *
        GameConstant.BodyPartRarityBaseMultiply[statIndex][
          dna[BodyPart.Horns][1]
        ]
    ),
    GameConstant.BaseBodyPartStat.map(
      (i: number[], statIndex: number) =>
        i[dna[BodyPart.Tail][0]] *
        GameConstant.BodyPartRarityBaseMultiply[statIndex][
          dna[BodyPart.Tail][1]
        ]
    ),
    GameConstant.BaseBodyPartStat.map(
      (i: number[], statIndex: number) =>
        i[dna[BodyPart.Back][0]] *
        GameConstant.BodyPartRarityBaseMultiply[statIndex][
          dna[BodyPart.Back][1]
        ]
    ),
  ];
  let baseStat = bodyPartsStat.reduce((a: number[], b: number[]) => {
    let tmp = [];
    for (var i = 0; i < b.length; i++) {
      tmp.push((a[i] || 0) + b[i]);
    }
    return tmp;
  }, []);
  //calculate max stat

  // baseStat = baseStat.map((i) => Math.round(i));
  let race = dna[BodyPart.Form][0];
  let currentStat: number[] = [
    //health
    baseStat[MongenStatType.Health] * 5 +
      150 +
      20 *
        baseStat[MongenStatType.Health] *
        (GameConstant.LevelStatMultiply[MongenStatType.Health][race] / 100) *
        (level + evolution - 2) *
        10,
    //sturdiness
    baseStat[MongenStatType.Sturdiness] *
      (1 +
        4 *
          (GameConstant.LevelStatMultiply[MongenStatType.Sturdiness][race] /
            100) *
          (level + evolution - 2)),
    //magic
    baseStat[MongenStatType.Magic] *
      (1 +
        6 *
          (GameConstant.LevelStatMultiply[MongenStatType.Magic][race] / 100) *
          (level + evolution - 2)),
    //aggresion
    baseStat[MongenStatType.Aggresion] *
      (1 +
        3 *
          (GameConstant.LevelStatMultiply[MongenStatType.Aggresion][race] /
            100) *
          (level + evolution - 2)),
  ];

  currentStat = currentStat.map((stat) => stat * morale_eff_value);

  let MAX_LEVEL = 100;
  let MAX_EVOL = 5;
  let ultimateMaxStat: number[] = [
    //health
    baseStat[MongenStatType.Health] * 5 +
      150 +
      20 *
        baseStat[MongenStatType.Health] *
        (GameConstant.LevelStatMultiply[MongenStatType.Health][race] / 100) *
        (MAX_LEVEL + MAX_EVOL - 2) *
        10,
    //sturdiness
    baseStat[MongenStatType.Sturdiness] *
      (1 +
        4 *
          (GameConstant.LevelStatMultiply[MongenStatType.Sturdiness][race] /
            100) *
          (MAX_LEVEL + MAX_EVOL - 2)),
    //magic
    baseStat[MongenStatType.Magic] *
      (1 +
        6 *
          (GameConstant.LevelStatMultiply[MongenStatType.Magic][race] / 100) *
          (MAX_LEVEL + MAX_EVOL - 2)),
    //aggresion
    baseStat[MongenStatType.Aggresion] *
      (1 +
        3 *
          (GameConstant.LevelStatMultiply[MongenStatType.Aggresion][race] /
            100) *
          (MAX_LEVEL + MAX_EVOL - 2)),
  ];
  //calculate power
  let basePower = Math.round(
    (Math.pow(baseStat[0], 0.525) *
      Math.pow(baseStat[1], 0.5) *
      Math.pow(baseStat[2], 0.42) *
      Math.pow(baseStat[3], 0.5)) /
      5
  );
  let currentPower = Math.round(
    (Math.pow(currentStat[0] / 10, 0.525) *
      Math.pow(currentStat[1], 0.5) *
      Math.pow(currentStat[2], 0.42) *
      Math.pow(currentStat[3], 0.5)) /
      10
  );

  let maxPower = Math.round(
    (Math.pow(ultimateMaxStat[0] / 10, 0.525) *
      Math.pow(ultimateMaxStat[1], 0.5) *
      Math.pow(ultimateMaxStat[2], 0.42) *
      Math.pow(ultimateMaxStat[3], 0.5)) /
      10
  );
  let rarity = 0;
  for (var i = GameConstant.RarityPowerRange.length - 1; i >= 0; i--) {
    if (basePower > GameConstant.RarityPowerRange[i]) {
      rarity = i;
      break;
    }
  }
  baseStat = baseStat.map((i: number) => Math.round(i));
  currentStat = currentStat.map((i: number) => Math.round(i));
  ultimateMaxStat = ultimateMaxStat.map((i: number) => Math.round(i));
  return {
    base: baseStat,
    max: currentStat,
    ultimateMax: ultimateMaxStat,
    rarity,
    basePower,
    currentPower,
    maxPower,
  };
}

function shuffle(array: any[]) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

function getDnaName(dna: number[]): string {
  if (dna.length === 1) {
    return Rarity[dna[0]];
  }
  return GameConstant.MongenName[dna[2] - 1][dna[0]][dna[1]];
}

function getDnaRarity(dna: number[]): string {
  if (dna.length === 1) {
    return Rarity[dna[0]];
  }
  return Rarity[dna[1]];
}

function getSkillImage(code: string): string {
  let skillType: SkillType;
  if (code.includes("BS")) {
    skillType = SkillType.Basic;
  } else {
    skillType = SkillType.Ultimate;
  }
  let src = `/assets/images/Skill/${SkillType[skillType]}/${code}.webp`;
  return src;
}

function getTroopImage(code: string): string {
  let src = `/assets/images/Troop/${code}.webp`;
  return src;
}

function formatName(name: string): string {
  if (!name) {
    return name;
  }
  if (name.includes("<color")) {
    let end = name.indexOf("</color>");
    name = name.slice(end + 8);
  }
  return name;
}

const getPlotIcon = (data: any) => {
  let reward = _.cloneDeep(data);

  // @ts-ignore
  if (
    (reward.plot_type === PlotType.Pasture && reward.race === -1) ||
    reward.rarity === -1 ||
    reward.plot_type === -1
  ) {
    return `/img/reward/plot-random.png`;
  }

  let rarity = reward.rarity as Rarity;
  let src = `/img/reward/plots/${PlotType[
    reward.plot_type
  ]?.toLowerCase()}-${rarity}.webp`;

  if (reward.sub_plot_type) {
    reward.sub_plot_type = Number(reward.sub_plot_type);
  }

  switch (reward.plot_type) {
    case PlotType.Pasture:
      src = `/img/reward/plots/${PlotType[
        reward.plot_type
      ]?.toLowerCase()}-${MongenRace[
        reward.race
      ]?.toLowerCase()}-${rarity}.webp`;
      break;
    case PlotType.Trap:
      if (reward.sub_plot_type) {
        src = `/img/reward/plots/${TrapType[
          reward.sub_plot_type
        ]?.toLowerCase()}-${PlotType[
          reward.plot_type
        ]?.toLowerCase()}-${rarity}.webp`;
      }
      break;
    case PlotType.Bombard:
      if (reward.sub_plot_type) {
        src = `/img/reward/plots/${BombardType[
          reward.sub_plot_type
        ]?.toLowerCase()}-${PlotType[
          reward.plot_type
        ]?.toLowerCase()}-${rarity}.webp`;
      }
      break;
    case PlotType.Barracks:
      if (reward.sub_plot_type) {
        src = `/img/reward/plots/${BarrackType[
          reward.sub_plot_type
        ]?.toLowerCase()}-${PlotType[
          reward.plot_type
        ]?.toLowerCase()}-${rarity}.webp`;
      }
      break;
    case PlotType.Tower:
      if (reward.sub_plot_type) {
        src = `/img/reward/plots/${TowerType[
          reward.sub_plot_type
        ]?.toLowerCase()}-${PlotType[
          reward.plot_type
        ]?.toLowerCase()}-${rarity}.webp`;
      }
      break;
    case PlotType.Camp:
      if (reward.sub_plot_type) {
        src = `/img/reward/plots/${CampType[
          reward.sub_plot_type
        ]?.toLowerCase()}-${PlotType[
          reward.plot_type
        ]?.toLowerCase()}-${rarity}.webp`;
      }
      break;
  }
  return src;
};

export function formatInputNumber(
  numberString: any,
  decimal = 5,
  quantityDefault?: number,
  max?: number
) {
  numberString = numberString?.replace(
    /[\uff01-\uff6e\uff61]/g,
    function (ch: any) {
      return String.fromCharCode(ch.charCodeAt(0) - 0xfee0);
    }
  );
  let charCode = numberString.charCodeAt(numberString.length - 1);
  if (charCode === 12290 || charCode === 129) {
    var numberString = numberString.replace(/.$/, ".");
  }
  if (decimal === 0) {
    numberString = numberString.replace(/[^0-9]/g, "");
  } else {
    numberString = numberString.replace(/[^0-9.]/g, "");
  }
  if (numberString.length == 0) return null;

  let index = numberString.indexOf(".");
  if (index > 0) {
    let string = numberString.slice(index + 1);
    string = string.replace(/[^0-9]/g, "");
    let value = parseInt(numberString.slice(0, index)) + "";
    if (value.length >= 10) value = value.slice(0, quantityDefault);
    if (string.length > decimal) {
      string = string.slice(0, decimal);
    }
    numberString = value + "." + string;
  } else if (index === 0) {
    let string = numberString.slice(index + 1);
    string = string.replace(/[^0-9]/g, "");
    let value = 0;
    if (string.length > decimal) {
      string = string.slice(0, decimal);
    }
    numberString = value + "." + string;
  } else {
    if (numberString.length >= 10)
      numberString = numberString.slice(0, quantityDefault);
    numberString = parseInt(numberString);
  }
  if (Number(numberString) > max) return max;
  return numberString;
}
const getSoulcoreIcon = (reward: any) => {
  // @ts-ignore
  if (reward.race === -1 || reward.rarity === -1) {
    return "/img/reward/soulcore-icon.svg";
  }
  let src = `/img/reward/egg/${MongenRace[reward.race].toLowerCase()}-${
    reward.rarity
  }.webp`;
  return src;
};

function getListTroopObj(arr: any[]): any {
  let list = arr.map((el: any) => {
    let troop = Troops.find((troop) => troop.id === el.id);

    if (!troop) {
      return null;
    }

    let skillsData = skill.getByIds([troop.basic_skill, troop.ultimate_skill]);
    let skills = skillsData.map((skill, idx) => {
      let desc_dmg =
        10 *
        (skill.dmg + Number(troop.mag) / 150) *
        (2 - 500 / (500 + Number(troop.mag) + 1 * 0.08));

      let desc_err = skill.er;
      let desc_et = skill.et * 0.5;
      let desc_ed = skill.ed;
      let desc_loop = skill.turn_loop;
      let desc_ct = skill.ct;
      let desc = t(skill.description)
        .replace("{0}", `${desc_dmg?.toFixed(2)}`)
        .replace("{1}", `${desc_err?.toFixed(2)}`)
        .replace("{2}", `${desc_et?.toFixed(2)}`)
        .replace("{3}", `${desc_ed?.toFixed(2)}`)
        .replace("{4}", `${desc_loop?.toFixed(2)}`)
        .replace("{5}", `${desc_ct?.toFixed(2)}`);

      return {
        type: skill.code.includes("BS") ? "Basic skill" : "Ultimate skill",
        stats: {
          dmg: skill.dmg,
          rng: skill.rng,
          eng: skill.eng,
          cd: skill.cd,
          msd: skill.speed,
        },
        image: `/assets/images/Skill/${
          skill.code.includes("BS") ? "Basic" : "Ultimate"
        }/${skill.code}.webp`,
        name: t(skill.name),
        code: t(skill.code),
        description: desc,
      };
    });

    return {
      code: troop?.code,
      rarity: troop?.rarity ?? 0,
      name: t(troop?.name),
      skills,
      training_cost: GameConstant.troopTrainingConfig[troop.rarity][1],
      training_time: GameConstant.troopTrainingConfig[troop.rarity][0],
      // Health: (Number(troop.hp) / 100) * troop.hp,
      // Aggression: (Number(troop.agr) / 100) * troop.agr,
      // Magic: (Number(troop.mag) / 100) * troop.mag,
      // Sturdiness: (Number(troop.std) / 100) * troop.std,
    };
  });
  return list;
}

function tryToParse(arr: string) {
  if (typeof arr == "string") {
    try {
      arr = JSON.parse(arr);
    } catch (err) {}
  }
  return arr;
}

const getRuneData = (id: any) => {
  let all = [
    {
      id: 1,
      name: "mrk_001",
      code: "mrk_001",
      effect: "booty_unit",
      value: 5,
    },
    {
      id: 2,
      name: "mrk_002",
      code: "mrk_002",
      effect: "hp_increase",
      value: 7.5,
    },
    {
      id: 3,
      name: "mrk_003",
      code: "mrk_003",
      effect: "std_increase",
      value: 12.5,
    },
    {
      id: 4,
      name: "mrk_004",
      code: "mrk_004",
      effect: "mg_decrease",
      value: 12.5,
    },
    {
      id: 5,
      name: "mrk_005",
      code: "mrk_005",
      effect: "agr_decrease",
      value: 12.5,
    },
  ];

  return all.find((i) => i.id === id);
};

function getRewardName(reward: RewardEntity) {
  switch (reward.reward_type) {
    case RewardType.LOCK_MAG:
      return "sMAG";
    case RewardType.UNLOCK_MAG:
      return "MAG";
    case RewardType.MSTR:
      return "MSTR";
    case RewardType.BOX:
      return "Box";
    case RewardType.FOOD:
      return "Food";
    case RewardType.PLOT:
      switch (reward.plot_type) {
        case PlotType.LandCore:
          return "Landcore";
        case PlotType.Pasture:
          return "Habitat";
        case PlotType.Breeding:
          return "Breeding den";
        case PlotType.Hatching:
          return "Hatchery";
        case PlotType.Production:
          return "Farm";
        case PlotType.Storage:
          return "Food storage";
        case PlotType.Barracks:
          return "Training Ground";
        case PlotType.Bombard:
          return "Turret";
        case PlotType.Tower:
          return "Watchtower";
        case PlotType.Trap:
          return "Landmine";
        case PlotType.Decor:
          return "Decor";
        case PlotType.Camp:
          return "Military Camp";
        default:
          return "Plot";
      }
    case RewardType.SOULCORE:
      return "Soulcore";
    case RewardType.EXP:
      return "EXP";
    case RewardType.EVOLUTION_ITEM:
      return "Evolution Item";
    case RewardType.TICKET:
      return `${Rarity[reward.rarity ?? 0]} Ticket`;
    case RewardType.NormalStone:
      return "Normal Stone";
    case RewardType.RerollStone:
      return "Reroll Stone";
    case RewardType.EliteStone:
      return "Elite Stone";
    case RewardType.NormalRuneStone:
      return "Normal Rune Stone";
    case RewardType.RerollRuneStone:
      return "Reroll RuneS tone";
    case RewardType.EliteRuneStone:
      return "Elite Rune Stone";
    case RewardType.Energy:
      return "Energy";
    case RewardType.Pigment:
      return "Pigment";
    case RewardType.BLESSING:
      return "Blessing";
    case RewardType.EMOJI:
      return "Emoji";
    case RewardType.BATTLEPASS_EXP:
      return "Battlepass EXP";
    case RewardType.ElectiveStoneCO:
    case RewardType.ElectiveStoneUC:
    case RewardType.ElectiveStoneRA:
    case RewardType.ElectiveStoneEP:
    case RewardType.ElectiveStoneLE:
      return "Elective Stone";
    case RewardType.ElectiveRuneStoneCO:
    case RewardType.ElectiveRuneStoneUC:
    case RewardType.ElectiveRuneStoneRA:
    case RewardType.ElectiveRuneStoneEP:
    case RewardType.ElectiveRuneStoneLE:
      return "Elective Rune Stone";
    case RewardType.Rune:
      return reward.rune_code;
    default:
      return "";
  }
}

function displayTime(time: string) {
  //@ts-ignore
  return dayjs(time).format(`h A UTC MMMM DD, YYYY`);
}

function getTicketType(rarity: TicketRarity) {
  switch (rarity) {
    case TicketRarity.Common:
      return NFTType.TicketCO;
    case TicketRarity.Uncommon:
      return NFTType.TicketUC;
    case TicketRarity.Rare:
      return NFTType.TicketRA;
    case TicketRarity.Epic:
      return NFTType.TicketEP;
    case TicketRarity.General:
      return NFTType.TicketGE;
    case TicketRarity.Global:
      return NFTType.TicketGL;
    default:
      throw new Error("invalid_type");
  }
}

function getTicketRarityName(rarity: TicketRarity) {
  switch (rarity) {
    case TicketRarity.Common:
      return "Common";
    case TicketRarity.Uncommon:
      return "Uncommon";
    case TicketRarity.Rare:
      return "Rare";
    case TicketRarity.Epic:
      return "Epic";
    case TicketRarity.Legendary:
      return "Legendary";
    case TicketRarity.General:
      return "General";
    case TicketRarity.Global:
      return "Global";
    default:
      return "";
  }
}

const utils = {
  getRuneData,
  tryToParse,
  getTroopImage,
  getSkillImage,
  getDnaName,
  getDnaRarity,
  shuffle,
  randomInt,
  formatHostUrl,
  getCountdownText,
  getRarityIcon,
  convertToInternationalCurrencySystem,
  getMongenStat,
  getFullMongenStat,
  getCountdownTextBySeconds,
  formatName,
  formatInputNumber,
  getSoulcoreIcon,
  getPlotIcon,
  getListTroopObj,
  getRewardName,
  displayTime,
  getTimeDisplay,
  getTicketType,
  getTicketRarityName,
};
export default utils;
