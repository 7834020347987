import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import { useEffect, useState } from 'react';

type InputProps = {
    fieldName: string,
    password: string,
    setPassword: any,
    className?: string,
    submit?: any,
    validation?: (data?: any) => string,
    setErr?: any
} & React.ComponentPropsWithoutRef<'input'>;

export const PasswordInput = ({
    fieldName,
    password,
    setPassword,
    className,
    validation,
    submit,
    setErr,
    ...props
}: InputProps) => {
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState("");

    const validateField = (data: any) => {
        if (data.length <= 0) {
            setError(`Field mustn't empty`);
        } else if (validation) {
            setError(validation(data));
        } else {
            setError("");
        }
    }

    useEffect(() => {
        setErr && setErr(error);
     }, [error])

    return (
        <div className={className}>
            <div className={clsx("text-center text-white flex mx-auto justify-center w-full overflow-hidden rounded-lg relative", !error && "my-3")}>
                <div className="flex absolute inset-y-0 left-0 pl-3 pointer-events-none flex-col">
                    <span className="text-gray text-sm mt-1">{fieldName}</span>
                </div>
                <input
                    {...props}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            submit && submit();
                        }
                    }}
                    type={showPassword ? 'text' : 'password'}
                    id="email-adress-icon"
                    className="text-md block w-full h-[48px] pl-3 pr-3 pt-5 text-black font-medium border-t-3 border-orange"
                    value={password}
                    onChange={(e: any) => {
                        validateField(e.target.value);
                        setPassword(e.target.value)
                    }}
                />
                <div className="relative cursor-pointer h-[48px] pr-3 text-center w-10 ml-auto bg-white pt-4 border-t-3 border-orange"
                    onClick={(e) => {
                        e.preventDefault();
                        setShowPassword(!showPassword);
                    }}
                >
                    {showPassword === true ? (
                        <EyeIcon
                            className='h-6 w-6 text-black items-center'
                            aria-hidden='true'
                        />
                    ) : (
                        <EyeOffIcon
                            className='h-6 w-6 text-black items-center'
                            aria-hidden='true'
                        />
                    )}
                </div>
            </div>
            {error && <div className="text-red pt-2">
                {error}
            </div>}
        </div>
    )
}