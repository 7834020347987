import { Rarity, Skill, SkillType } from "interfaces";
let data = require("../interfaces/skill.json");
function getAll(): Skill[] {
  return data;
}

function getByIds(ids: number[]): Skill[] {
  let skills: Skill[] = getAll();
  let rs = skills.filter((s: Skill) => ids?.includes(s.id));
  return rs;
}

function getSkill({
  maxRarity,
  skillType,
}: {
  maxRarity?: Rarity;
  skillType?: SkillType;
  isElite?: boolean;
}): Skill[] {
  let all = getAll();
  let rs = all.filter((s: Skill) => {
    if (maxRarity !== null && maxRarity !== undefined && s.rarity > maxRarity) {
      return false;
    }
    if (skillType !== null && skillType !== undefined && skillType !== s.type) {
      return false;
    }
    return true;
  });
  return rs;
}
let skill = { getAll, getByIds, getSkill };

export default skill;
