// @ts-ignore
import { useEffect, useState } from "react";
import { FitToViewport } from "react-fit-to-viewport";
// @ts-ignore
import { Button, Modal } from "semantic-ui-react";
// @ts-ignore
import { getDiscordAuthorizeUrl } from "config";
import { MongenRace } from "interfaces";
import { api, ui } from "services";
import { useTranslation } from "react-i18next";
import AutoLoadingButton from "components/loadingbutton";

export const TelegramConnect = (handleData: any) => {
    // @ts-ignore
    if (!window?.Telegram) {
        return;
    }

    // @ts-ignore
    window.Telegram.Login.auth(
        { bot_id: '6197127841', request_access: true },
        (data: any) => {
            if (!data) {
                // authorization failed
            }

            // Here you would want to validate data like described there https://core.telegram.org/widgets/login#checking-authorization
            handleData(data);
        }
    );
}

export const CelestPlanet = ({ claimed_quest, updateQuest, claimed_star, claimStar, config }: any) => {
    const [selectedPartner, setSelectedPartner] = useState(0)
    const [popupTask, setPopupTask] = useState(null);
    const discordToken = sessionStorage.getItem('discordToken');
    const [telegramId, setTelegramId] = useState(sessionStorage.getItem('telegramId'))
    const [telegramStep, setTelegramStep] = useState(1);
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    const increaseTask = async (task_id: number, discordToken?: string, telegramId?: string) => {
        try {
            setLoading(true)
            let telegramIdStorage = sessionStorage.getItem('telegramId');

            let { customerQuest, rewards } = await api.postEventDeal('/birthday/increase-celest-task', {
                task_id,
                discordToken,
                telegramId: telegramId ?? telegramIdStorage
            })
            updateQuest && updateQuest(customerQuest)
            setPopupTask(null);
            ui.openRewardPopup(rewards);
        } catch (err: any) {
            if (err?.message === 'invalid_discord_token') {
                sessionStorage.removeItem('discordToken')
                let discordConfig = config?.discordConfig
                if (!discordConfig) return;
                let url = getDiscordAuthorizeUrl(discordConfig)
                window.open(url, '_self')
            } else {
                console.log({ err })
                ui.alertFailed(t(err?.message), "")
            }
        } finally {
            setLoading(false)
        }
    }

    const partnerData = [
        {
            name: 'Aura Network',
            icon: '/assets/images/partner/aura.png',
            description: 'Aura Network is an ecosystem built to accelerate global NFTs adoption. Aura Network focuses on building the Internet of NFTs and bringing NFT and web3 to the masse',
            task: [
                {
                    quest_id: 1,
                    title: <div>
                        <div className="text-3xl">
                            Task 1
                        </div>
                    </div>,
                    description: <div>
                        <div className="text-3xl mt-10 font-bold font-cookie-run break-words">
                            VISIT Website https://aura.network/
                        </div>
                    </div>,
                    task_pos: 0,
                    function: <div className="flex justify-center mx-auto text-center w-full">
                        <Button
                            color="blue"
                            style={{
                                background: '#62FFFF',
                                width: '180px',
                                color: 'black',
                                borderRadius: '30px'
                            }}
                            className="w-[84px] bg-[#62FFFF]"
                            onClick={async () => {
                                window.open('https://aura.network', '_blank')
                                if (!claimed_quest?.includes(1)) {
                                    await increaseTask(1);
                                }
                            }}
                        >
                            <div className="text-3xl font-bold font-cookie-run">Go to page</div>
                        </Button>
                    </div>
                },
                {
                    quest_id: 2,
                    title: <div>
                        <div className="text-3xl">
                            Task 2
                        </div>
                    </div>,
                    description: <div>
                        <div className="text-3xl mt-10 font-bold font-cookie-run break-words">
                            FOLLOW Twitter https://twitter.com/AuraNetworkHQ
                        </div>
                    </div>,
                    function: <div className="flex justify-center mx-auto text-center w-full">
                        <Button
                            color="blue"
                            style={{
                                background: '#62FFFF',
                                width: '180px',
                                color: 'black',
                                borderRadius: '30px'
                            }}
                            className="w-[84px] bg-[#62FFFF]"
                            onClick={async () => {
                                window.open('https://twitter.com/AuraNetworkHQ', '_blank')
                                if (!claimed_quest?.includes(2)) {
                                    await increaseTask(2);
                                }
                            }}
                        >
                            <div className="text-3xl font-bold font-cookie-run">Follow</div>
                        </Button>
                    </div>,
                    task_pos: 2,
                },
                {
                    quest_id: 3,
                    title: <div>
                        <div className="text-3xl">
                            Task 3
                        </div>
                    </div>,
                    description: <div>
                        <div className="text-3xl mt-10 font-bold font-cookie-run break-words">
                            JOIN Discord https://discord.com/invite/PNCp4CXj7K
                        </div>
                    </div>,
                    function: <div className="flex justify-center mx-auto text-center w-full gap-4">
                        <Button
                            color="blue"
                            style={{
                                background: '#62FFFF',
                                width: '180px',
                                color: 'black',
                                borderRadius: '30px'
                            }}
                            className="w-[84px] bg-[#62FFFF]"
                            onClick={async () => {
                                window.open('https://discord.com/invite/PNCp4CXj7K', '_blank')
                            }}
                        >
                            <div className="text-xl font-bold font-cookie-run">
                                Go to page
                            </div>
                        </Button>
                        <AutoLoadingButton
                            color="blue"
                            style={{
                                background: '#62FFFF',
                                width: '180px',
                                color: 'black',
                                borderRadius: '30px'
                            }}
                            disabled={claimed_quest?.includes(3)}
                            className="w-[84px] bg-[#62FFFF]"
                            loading={loading}
                            onClick={async () => {
                                if (claimed_quest?.includes(3)) {
                                    return;
                                }
                                if (!discordToken) {
                                    let discordConfig = config?.discordConfig
                                    if (!discordConfig) return;
                                    let url = getDiscordAuthorizeUrl(discordConfig)
                                    window.open(url, '_self')
                                } else {
                                    await increaseTask(3, discordToken);
                                    sessionStorage.removeItem('discordToken')
                                }
                            }}
                        >
                            <div className="text-xl font-bold font-cookie-run">{!discordToken ? 'Connect Discord' : 'Verify'}</div>
                        </AutoLoadingButton>
                    </div>,
                    task_pos: 4,
                },
                {
                    quest_id: 4,
                    telegramUsername: "@AuraNetworkOfficial",
                    telegramLink: "https://t.me/AuraNetworkOfficial",
                    verifyFn: async () => {
                        if (!claimed_quest?.includes(4)) {
                            await increaseTask(4, null, telegramId)
                        }
                    },
                    title: <div>
                        <div className="text-3xl">
                            Task 4
                        </div>
                    </div>,
                    description: <div className="w-full mx-auto flex justify-center h-full">
                        <div className="text-center text-white text-2xl font-cookie-run font-bold self-center w-full">
                            {telegramStep === 1 && <span>STEP 1/3: JOIN Telegram @AuraNetworkOfficial <br /> to complete this task.</span>}
                            {telegramStep === 2 && <span>STEP 2/3: Start @monsterrabirthday_bot <br />to verify this task</span>}
                            {telegramStep === 3 && <span>STEP 3/3: Verify this task to claim your rewards</span>}
                        </div>
                    </div>,
                    function: <div className="flex justify-center mx-auto text-center w-full gap-4">
                        {(telegramStep === 1 || !telegramId) && <Button
                            color="blue"
                            style={{
                                background: '#62FFFF',
                                width: '180px',
                                color: 'black',
                                borderRadius: '30px'
                            }}
                            className="w-[84px] bg-[#62FFFF]"
                            onClick={async () => {
                                if (!telegramId) {
                                    TelegramConnect((user: any) => {
                                        console.log({ user })
                                        if (user?.id) {
                                            sessionStorage.setItem('telegramId', user.id)
                                            setTelegramId(user.id)
                                            window.open('https://t.me/AuraNetworkOfficial', '_blank')
                                            setTelegramStep(2)
                                        }
                                    })
                                } else {
                                    window.open('https://t.me/AuraNetworkOfficial', '_blank')
                                    setTelegramStep(2)
                                }
                            }}
                        >
                            <div className="text-xl font-bold font-cookie-run">Open Telegram</div>
                        </Button>}
                        {telegramStep === 2 && <Button
                            color="blue"
                            style={{
                                background: '#62FFFF',
                                width: '180px',
                                color: 'black',
                                borderRadius: '30px'
                            }}
                            className="w-[84px] bg-[#62FFFF]"
                            onClick={async () => {
                                window.open(`https://t.me/monsterrabirthday_bot?start=1134460746`, "_blank");
                                setTelegramStep(3)
                            }}
                        >
                            <div className="text-xl font-bold font-cookie-run">
                                Open Telegram
                            </div>
                        </Button>
                        }
                        {telegramStep === 3 && <AutoLoadingButton
                            color="blue"
                            style={{
                                background: '#62FFFF',
                                width: '180px',
                                color: 'black',
                                borderRadius: '30px'
                            }}
                            className="w-[84px] bg-[#62FFFF]"
                            loading={loading}
                            onClick={async () => {
                                if (!claimed_quest?.includes(4)) {
                                    await increaseTask(4, null, telegramId)
                                }
                            }}
                        >
                            <div className="text-xl font-bold font-cookie-run">
                                Verify
                            </div>
                        </AutoLoadingButton>
                        }
                    </div>,
                    task_pos: 6,
                },
            ]
        },
        {
            name: 'Meta Apes',
            icon: '/assets/images/partner/meta_apes.png',
            description: 'Meta Apes is a free-to-play, win-to-earn MMO strategy mobile game launched in May 2022. The game is set in a post-apocalyptic world dominated by apes where the players must use their strategic prowess and team up with others to conquer the world and win the space race. Meta Apes amassed over 400k players and is currently available on both Google Play & App Store.',
            task: [
                {
                    quest_id: 5,
                    title: <div>
                        <div className="text-3xl">
                            Task 1
                        </div>
                    </div>,
                    description: <div>
                        <div className="text-3xl mt-10 font-bold font-cookie-run break-words">
                            Follow @MetaApesGame on Twitter: https://twitter.com/MetaApesGame
                        </div>
                    </div>,
                    function: <div className="flex justify-center mx-auto text-center w-full">
                        <AutoLoadingButton
                            color="blue"
                            style={{
                                background: '#62FFFF',
                                width: '180px',
                                color: 'black',
                                borderRadius: '30px'
                            }}
                            className="w-[84px] bg-[#62FFFF]"
                            loading={loading}
                            onClick={async () => {
                                window.open('https://twitter.com/MetaApesGame', '_blank')
                                if (!claimed_quest?.includes(5)) {
                                    await increaseTask(5);
                                }
                            }}
                        >
                            <div className="text-3xl font-bold font-cookie-run">Go to page</div>
                        </AutoLoadingButton>
                    </div>,
                    task_pos: 0,
                },
                {
                    quest_id: 6,
                    title: <div>
                        <div className="text-3xl">
                            Task 2
                        </div>
                    </div>,
                    description: <div>
                        <div className="text-3xl mt-10 font-bold font-cookie-run break-words">
                            Join MetaApes Discord Server: https://discord.gg/metaapesgame
                        </div>
                    </div>,
                    function: <div className="flex justify-center mx-auto text-center w-full gap-4">
                        <Button
                            color="blue"
                            style={{
                                background: '#62FFFF',
                                width: '180px',
                                color: 'black',
                                borderRadius: '30px'
                            }}
                            className="w-[84px] bg-[#62FFFF]"
                            onClick={async () => {
                                window.open('https://discord.gg/metaapesgame', '_blank')
                            }}
                        >
                            <div className="text-xl font-bold font-cookie-run">
                                Go to page
                            </div>
                        </Button>
                        <AutoLoadingButton
                            color="blue"
                            style={{
                                background: '#62FFFF',
                                width: '180px',
                                color: 'black',
                                borderRadius: '30px'
                            }}
                            disabled={claimed_quest?.includes(6)}
                            className="w-[84px] bg-[#62FFFF]"
                            loading={loading}
                            onClick={async () => {
                                if (claimed_quest?.includes(6)) {
                                    return;
                                }
                                if (!discordToken) {
                                    let discordConfig = config?.discordConfig
                                    if (!discordConfig) return;
                                    let url = getDiscordAuthorizeUrl(discordConfig)
                                    window.open(url, '_self')
                                } else {
                                    await increaseTask(6, discordToken);
                                    sessionStorage.removeItem('discordToken')
                                }
                            }}
                        >
                            <div className="text-xl font-bold font-cookie-run">{!discordToken ? 'Connect Discord' : 'Verify'}</div>
                        </AutoLoadingButton>
                    </div>,
                    task_pos: 3,
                },
                {
                    quest_id: 7,
                    telegramUsername: "@metaapesgame",
                    telegramLink: "https://t.me/metaapesgame",
                    verifyFn: async () => {
                        if (!claimed_quest?.includes(7)) {
                            await increaseTask(7, null, telegramId)
                        }
                    },
                    title: <div>
                        <div className="text-3xl">
                            Task 3
                        </div>
                    </div>,
                    description: <div>
                        <div className="text-3xl mt-10 font-bold font-cookie-run break-words">
                            Join Meta Apes Telegram Channel: https://t.me/metaapesgame
                        </div>
                    </div>,
                    function: <div className="flex justify-center mx-auto text-center w-full gap-4">
                        <Button
                            color="blue"
                            style={{
                                background: '#62FFFF',
                                width: '180px',
                                color: 'black',
                                borderRadius: '30px'
                            }}
                            className="w-[84px] bg-[#62FFFF]"
                            onClick={async () => {
                                window.open('https://t.me/metaapesgame', '_blank')
                            }}
                        >
                            <div className="text-xl font-bold font-cookie-run">Go to page</div>
                        </Button>
                        {telegramId && <AutoLoadingButton
                            color="blue"
                            style={{
                                background: '#62FFFF',
                                width: '180px',
                                color: 'black',
                                borderRadius: '30px'
                            }}
                            disabled={claimed_quest?.includes(7)}
                            className="w-[84px] bg-[#62FFFF]"
                            loading={loading}
                            onClick={async () => {
                                if (claimed_quest?.includes(7)) {
                                    return;
                                }
                                await increaseTask(7, null, telegramId)
                            }}
                        >
                            <div className="text-xl font-bold font-cookie-run">{'Verify'}</div>
                        </AutoLoadingButton>}
                        {!telegramId && !claimed_quest?.includes(7) && <AutoLoadingButton
                            color="blue"
                            style={{
                                background: '#62FFFF',
                                width: '180px',
                                color: 'black',
                                borderRadius: '30px'
                            }}
                            disabled={claimed_quest?.includes(7)}
                            className="w-[84px] bg-[#62FFFF]"
                            onClick={async () => {
                                TelegramConnect((user: any) => {
                                    console.log({ user })
                                    if (user?.id) {
                                        sessionStorage.setItem('telegramId', user.id)
                                        setTelegramId(user.id)
                                    }
                                })
                            }}
                        >
                            <div className="text-xl font-bold font-cookie-run">Connect</div>
                        </AutoLoadingButton>}
                    </div>,
                    task_pos: 6,
                },
            ]
        },
        {
            name: 'Crescent Shine',
            icon: '/assets/images/partner/creshent_shine.png',
            task: [
                {
                    quest_id: 8,
                    title: <div>
                        <div className="text-3xl">
                            Task 1
                        </div>
                    </div>,
                    description: <div>
                        <div className="text-3xl mt-10 font-bold font-cookie-run break-words">
                            Follow @CS_web3studio on Twitter: https://twitter.com/CS_web3studio
                        </div>
                    </div>,
                    function: <div className="flex justify-center mx-auto text-center w-full">
                        <AutoLoadingButton
                            color="blue"
                            style={{
                                background: '#62FFFF',
                                width: '180px',
                                color: 'black',
                                borderRadius: '30px'
                            }}
                            className="w-[84px] bg-[#62FFFF]"
                            loading={loading}
                            onClick={async () => {
                                window.open('https://twitter.com/CS_web3studio', '_blank')
                                if (!claimed_quest?.includes(8)) {
                                    await increaseTask(8);
                                }
                            }}
                        >
                            <div className="text-3xl font-bold font-cookie-run">Go to page</div>
                        </AutoLoadingButton>
                    </div>,
                    task_pos: 0,
                },
                {
                    quest_id: 9,
                    telegramUsername: "@crescentshinestudioofficial",
                    telegramLink: "https://t.me/crescentshinestudioofficial",
                    verifyFn: async () => {
                        if (!claimed_quest?.includes(9)) {
                            await increaseTask(9, null, telegramId)
                        }
                    },
                    title: <div>
                        <div className="text-3xl">
                            Task 2
                        </div>
                    </div>,
                    task_pos: 6,
                },
            ]
        },
    ]

    const TaskPosition = [
        {
            "top": 386 - 47,
            "left": 340 - 40,
        },
        {
            "top": 371 - 45,
            "left": 458 - 40,
        },
        {
            "top": 315 - 58,
            "left": 676 - 40,
        },
        {
            "top": 244 - 55,
            "left": 894 - 40,
        },
        {
            "top": 187 - 28,
            "left": 1112 - 40,
        },
        {
            "top": 178 - 45,
            "left": 1330 - 40
        },
        {
            "top": 260 - 40,
            "left": 1600 - 250
        },
    ]

    /* PAGE 5 */
    return (
        <div className="transform-origin w-full h-full min-w-screen min-h-screen pt-8 flex justify-center bg-banner5 bg-contain" id='celest-planet'>
            {!popupTask?.telegramUsername && <Modal
                key="emailForm"
                open={!!popupTask}
                style={{ width: "unset" }}
                className="bg-popup bg-cover mini-scale"
            >
                <div
                    className="max-w-[564px] h-[333px] w-screen"
                >
                    <div className="max-w-[564px] h-[333px] w-screen pb-8">
                        <div className="text-red w-[50px] md:w-[64px] cursor-pointer absolute float-right -right-2 -top-2 z-[4]"
                            onClick={() => {
                                setPopupTask(null);
                            }}
                        >
                            <img src="/assets/images/footer/close.png" alt="close" className="" />
                        </div>
                        <div className="h-3/5 w-full relative">
                            <div className="text-center text-white text-xl py-6 font-extrabold main-font-bold">
                                {popupTask?.title ?? ""}
                            </div>
                            <div className="w-full mx-auto flex justify-center h-full">
                                <div className="text-center text-white">
                                    {popupTask?.description}
                                </div>
                            </div>
                        </div>
                        <div className="text-center items-center mx-auto bottom-10 absolute justify-center w-full">
                            {popupTask?.function ?? ""}
                        </div>
                    </div>
                </div>
            </Modal >}

            {popupTask?.telegramUsername && <TelegramModal popup={popupTask} closePopup={() => {
                setPopupTask(null)
            }}
                telegramUsername={popupTask.telegramUsername}
                telegramLink={popupTask.telegramLink}
                verifyFn={popupTask.verifyFn}
            />
            }

            <FitToViewport
                width={1920}
                height={1080}
                minZoom={0.1}
                maxZoom={1}
                className="overflow-hidden"
            >
                <div className="text-4xl text-white font-cookie-run text-header-shadow text-center mt-4">
                    CHECK-IN
                </div>
                <div className="text-6xl pt-2 font-cookie-bold text-white text-header-shadow text-center">
                    CELEST PLANET
                </div>
                <div className="relative w-full">
                    <div className="flex justify-center gap-8 mt-14">
                        {partnerData.map((data, idx) => {
                            return (
                                <div className="relative">
                                    <div
                                        onClick={() => {
                                            setSelectedPartner(idx)
                                        }}
                                        className={`flex gap-2 ${selectedPartner === idx ? "bg-[#4f93b4]" : "bg-[#4a6894]"} hover:bg-[#4f93b4] cursor-pointer rounded-2xl w-[220px] self-center justify-center h-[64px]`}>
                                        <img src={data.icon} alt={data.name} className="w-[42px] h-[42px] self-center" />
                                        <div className="text-white font-cookie-run text-xl font-bold justify-center self-center">
                                            {data.name}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                    <div className="scale-[0.8] md:scale-[1] relative w-3/4 mx-auto">
                        {partnerData[selectedPartner].task.map((i, idx) => {
                            let taskPosition = TaskPosition[i.task_pos]
                            let isLast = i.task_pos === TaskPosition.length - 1
                            let isOpen = claimed_quest?.includes(i.quest_id - 1) || idx === 0
                            return (
                                <div className="absolute w-[187px] cursor-pointer"
                                    onClick={() => {
                                        if (isOpen)
                                            setPopupTask(i)
                                    }}
                                    style={{
                                        left: `${taskPosition.left}px`,
                                        top: `${taskPosition.top}px`,
                                        zIndex: 4,
                                    }}>
                                    {isLast ? <div className="task-container self-center font-cookie-run text-center justify-center flex mx-auto">
                                        <div className="self-center text-uppercase font-bold relative w-full">
                                            {isOpen ? `Task ${idx + 1}` : '?'}
                                        </div>
                                    </div> : <div className="-translate-y-[110px]"><div className="task-container self-center font-cookie-run text-center justify-center flex mx-auto">
                                        <div className="self-center text-uppercase font-bold relative w-full">
                                            {isOpen ? `Task ${idx + 1}` : '?'}
                                            <img src='/assets/images/celest-planet/gift.png' alt='task' className="celest-gift w-[187px] absolute scale-[1.5]" />
                                        </div></div>
                                    </div>}
                                    {isLast ?
                                        <>
                                            {partnerData[selectedPartner].name === 'Aura Network' && <img src='/assets/images/partner/aura.gif' alt='line'
                                                className="w-[187px] translate-y-[34px] -translate-x-[6px] scale-[1.5] absolute" />}
                                            {partnerData[selectedPartner].name === 'Meta Apes' && <img src='/assets/images/partner/meta_apes.png' alt='line'
                                                className="w-[187px] translate-y-[34px] -translate-x-[6px] scale-[1.5] absolute partner-anim" />}
                                            {partnerData[selectedPartner].name === 'Crescent Shine' && <img src='/assets/images/partner/creshent_shine.png' alt='line'
                                                className="w-[187px] translate-y-[34px] -translate-x-[6px] scale-[1.5] absolute partner-anim" />}
                                        </>
                                        :
                                        <img src='/assets/images/celest-planet/task.png' alt='task' className="w-[187px] absolute" />
                                    }
                                    {claimed_quest?.includes(i.quest_id) && <img src='/assets/images/celest-planet/check.png'
                                        alt='check' className={`left-[50%] -translate-x-[50%] ${isLast ? 'top-[180px]' : 'top-[102px]'} absolute`} />}
                                </div>
                            )
                        })}
                        <img src='/assets/images/celest-planet/line.png' alt='line' className="w-9/10 translate-y-[200px]" style={{
                            zIndex: 2
                        }} />
                        <img src='/assets/images/celest-planet/mongen.png' alt='line' className="w-[400px] absolute left-[60px] top-[0px] -translate-y-[60px]" />
                    </div>
                </div>
                {!claimed_star?.includes(MongenRace.Celest) && <img src='/assets/images/beast-planet/claim_btn.png'
                    className={`${claimed_quest?.length < 7 && 'disable-claim'} cursor-pointer mx-auto mt-4 absolute bottom-[200px] left-[50%] -translate-x-[50%]`} alt="elipse"
                    onClick={async () => {
                        if (claimed_quest?.length >= 7) {
                            await claimStar(MongenRace.Celest)
                        }
                    }}
                />}
            </ FitToViewport>
        </div>
    )
}

const TelegramModal = ({ popup, closePopup, telegramUsername, telegramLink, verifyFn }: any) => {
    const [telegramStep, setTelegramStep] = useState(1);
    const [telegramId, setTelegramId] = useState(sessionStorage.getItem('telegramId'))
    const [loading, setLoading] = useState(false)

    return (
        <Modal
            key="emailForm"
            open={!!popup}
            style={{ width: "unset" }}
            className="bg-popup bg-cover mini-scale"
            closeIcon={
                <div className="text-red w-[50px] md:w-[64px] cursor-pointer absolute float-right -right-2 -top-2" style={{ zIndex: 2 }}
                    onClick={() => {
                        closePopup && closePopup();
                        setTelegramStep(1)
                    }}
                >
                    <img src="/assets/images/footer/close.png" alt="close" className="" />
                </div>
            }
        >
            <div
                className="max-w-[564px] h-[333px] w-screen"
            >
                <div className="max-w-[564px] h-[333px] w-screen pb-8">
                    <div className="w-full h-full">
                        <div className="h-3/5 w-full relative">
                            <div className="text-center text-white text-xl py-6 font-extrabold main-font-bold">
                                Join Telegram channel
                            </div>
                            <div className="w-full mx-auto flex justify-center h-full">
                                <div className="text-center text-white text-2xl font-cookie-run font-bold self-center w-full">
                                    {telegramStep === 1 && <span>STEP 1/3: Join group {telegramUsername} <br /> to complete this task.</span>}
                                    {telegramStep === 2 && <span>STEP 2/3: Start @monsterrabirthday_bot <br />to verify this task</span>}
                                    {telegramStep === 3 && <span>STEP 3/3: Verify this task to claim your rewards</span>}
                                </div>
                            </div>
                        </div>
                        <div className="text-center items-center mx-auto bottom-10 absolute justify-center w-full">
                            <div className="flex justify-center mx-auto text-center w-full gap-8">
                                {(telegramStep === 1 || !telegramId) && <Button
                                    color="blue"
                                    style={{
                                        background: '#62FFFF',
                                        width: '180px',
                                        color: 'black',
                                        borderRadius: '30px'
                                    }}
                                    className="w-[84px] bg-[#62FFFF]"
                                    onClick={async () => {
                                        if (!telegramId) {
                                            TelegramConnect((user: any) => {
                                                console.log({ user })
                                                if (user?.id) {
                                                    sessionStorage.setItem('telegramId', user.id)
                                                    setTelegramId(user.id)
                                                    window.open(telegramLink, '_blank')
                                                    setTelegramStep(2)
                                                }
                                            })
                                        } else {
                                            window.open(telegramLink, '_blank')
                                            setTelegramStep(2)
                                        }
                                    }}
                                >
                                    <div className="text-xl font-bold font-cookie-run">Open Telegram</div>
                                </Button>}
                                {telegramStep === 2 && <Button
                                    color="blue"
                                    style={{
                                        background: '#62FFFF',
                                        width: '180px',
                                        color: 'black',
                                        borderRadius: '30px'
                                    }}
                                    className="w-[84px] bg-[#62FFFF]"
                                    onClick={async () => {
                                        window.open(`https://t.me/monsterrabirthday_bot`, "_blank");
                                        setTelegramStep(3)
                                    }}
                                >
                                    <div className="text-xl font-bold font-cookie-run">
                                        Open Telegram
                                    </div>
                                </Button>
                                }
                                {telegramStep === 3 && <AutoLoadingButton
                                    color="blue"
                                    style={{
                                        background: '#62FFFF',
                                        width: '180px',
                                        color: 'black',
                                        borderRadius: '30px'
                                    }}
                                    loading={loading}
                                    className="w-[84px] bg-[#62FFFF]"
                                    onClick={async () => {
                                        setLoading(true)
                                        await verifyFn()
                                        setLoading(false)
                                    }}
                                >
                                    <div className="text-xl font-bold font-cookie-run">
                                        Verify
                                    </div>
                                </AutoLoadingButton>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal >

    )
}