import { Character } from "home"
import { HappyBirthdayResource, RewardEntity, RewardType } from "interfaces"
import { useEffect, useState } from "react"
import { utils } from "services"

const RewardLayout = ({ reward, src }: { reward: RewardEntity, src?: string }) => {
    return (
        <div className="w-[100px] h-[100px] justify-center mx-3 rounded-t-lg">
            <div className="w-full h-full top-0 left-0 flex items-center justify-center">
                <img src={src} className="w-[100px] h-[100px]" alt="reward" />
                {reward.rarity != null && (
                    <div className="text-red cursor-pointer absolute translate-x-[80px] -translate-y-8">
                        <img
                            src={utils.getRarityIcon(reward.rarity)}
                            alt="rarity"
                            className="w-10 h-10"
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

export const RewardsDisplay = ({ reward }: { reward: RewardEntity }) => {
    const [component, setComponent] = useState(<></>);

    useEffect(() => {
        const load = () => {
            let component;
            switch (reward.reward_type) {
                case RewardType.PLOT:
                    component = <RewardLayout reward={reward} src={utils.getPlotIcon(reward)} />
                    break;
                case RewardType.SPIN_TURN:
                    component = <RewardLayout reward={reward} src={'/assets/images/chaos-planet/ticket.png'} />
                    break;
                case RewardType.BirthdayStar:
                    component = <RewardLayout reward={reward} src={'/img/star-fill.png'} />
                    break;
                case RewardType.BirthdayCharacter:
                    component = <Character text={HappyBirthdayResource[Number(reward.character)]} width="15vw" className='max-w-[64px] max-h-[64px]' />
                    break;
                case RewardType.SOULCORE:
                    component = <RewardLayout reward={reward} src={utils.getSoulcoreIcon(reward)} />
                    break;
                default:
                    component = (<div></div>)
                    break;
            }
            setComponent(<>{component}</>);
        }
        load()
    }, [])

    return (
        <>
            {component}
        </>
    )
}