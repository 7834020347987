import { createSlice } from "@reduxjs/toolkit";
import { UserEntity } from "interfaces";
const initialState: UserEntity = {
  id: 0,
  name: "",
  role: 0,
  A: 1,
  B: false,
  C: "",
  D: "",
  E: 0,
  F: 0,
  G: 0,
  H: 0,
  I: 0,
  J: 0,
  K: 0,
  L: 0,
  M: 0,
  N: 0,
  O: 0,
  P: 0,
  Q: 0,
  R: 0,
  S: 0,
  T: false,
  U: 0,
  V: 0,
  X: null,
  Y: null,
  Z: 0,
  AA: 0,
  AB: 0,
  AC: 0,
  AD: 0,
  AE: null,
  AF: 0,
  AG: 0,
  AH: 0,
  AI: 0,
  AJ: 0,
  AK: 0,
  AR: 0,
  AS: 0,
  isJoinnedClan: false,
  isClanJoinTournament: false
};
const todosSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    clearUserInfo() {
      return null;
    },
    setUserInfo(state: any, action) {
      let tmp = { ...(state || {}) };
      for (var i in action.payload) {
        tmp[i] = action.payload[i];
      }
      return tmp;
    },
    bulkSetUserInfo(state: any, action: any) {
      let tmp: any = { ...state, ...initialState };
      for (var i in action.payload) {
        tmp[i] = action.payload[i];
      }
      return tmp;
    },
  },
});

export const { setUserInfo, clearUserInfo, bulkSetUserInfo } =
  todosSlice.actions;

export default todosSlice.reducer;
