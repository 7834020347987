import { createSlice } from "@reduxjs/toolkit";
import {  CustomerRole, LoginType } from "interfaces";
const initialState: any = {
  [LoginType.BSC]: {},
  [LoginType.Terra]: {},
  [LoginType.Email]: {},
  [LoginType.Avalanche]: {},
  [LoginType.Okex]: {},
  [LoginType.Facebook]: {},
  [LoginType.Google]: {},
  [LoginType.Farmer]: [],
};
const todosSlice = createSlice({
  name: "auths",
  initialState,
  reducers: {
    removeAuthInfo(state: any, action) {
      let tmp = { ...(state || {}) };
      tmp[action.payload.type] = {};
      return tmp;
    },
    clearAuthInfos() {
      return initialState;
    },
    setAuthInfos(state: any, action) {
      let tmp = { ...(state || {}) };
      let usernameArray = [];
      for (var i in action.payload) {
        const index = action.payload[i].D ?? LoginType.BSC;
        switch (index) {
          case LoginType.BSC:
          case LoginType.Avalanche:
          case LoginType.Okex:
          case LoginType.Terra:
          case LoginType.Google:
          case LoginType.Facebook:
            tmp[index] = action.payload[i];
            break;
          case LoginType.Email:
            const role = action.payload[i].E ?? CustomerRole.Owner;
            switch (role) {
              case CustomerRole.Owner:
                tmp[index] = action.payload[i];
                break;
              case CustomerRole.Farmer:
                usernameArray.push(action.payload[i]);
                break;
            }
            break;
          default:
            break;
        }
      }
      if (usernameArray.length > 0) {
        tmp[LoginType.Farmer] = usernameArray;
      }
      return tmp;
    },
  },
});

export const { removeAuthInfo, setAuthInfos, clearAuthInfos } = todosSlice.actions;

export default todosSlice.reducer;
