import clsx from "clsx";
import { ButtonColor, ButtonVariant } from "interfaces";
import * as React from "react";
import { Button } from "semantic-ui-react";

type ButtonProps = {
  hover?: boolean;
  loading?: boolean;
  color?: keyof typeof ButtonColor;
  variant?: keyof typeof ButtonVariant;
} & React.ComponentPropsWithoutRef<"button">;

export default function CustomButton({
  children,
  className,
  disabled: buttonDisabled,
  loading,
  color,
  variant = "primary",
  ...rest
}: ButtonProps) {
  const disabled = loading || buttonDisabled;

  return (
    <button
      {...rest}
      disabled={disabled}
      className={clsx(
        className,
        `${!disabled && "opacity-90 hover:opacity-100"}`,
        "w-full rounded-md py-2 font-medium main-font-medium",
        `custom-btn-color-${color}`,
        `${disabled && "btn-disabled"}`,
        [variant === "primary" && [], variant === "outline" && []],
        "disabled:cursor-not-allowed"
      )}
    >
      {loading ? (
        <div className="flex flex-cols w-full justify-center">{children}</div>
      ) : (
        children
      )}
    </button>
  );
}

export function ArtButton({
  children,
  className,
  disabled: buttonDisabled,
  loading,
  color,
  hover,
  variant = "primary",
  ...rest
}: ButtonProps) {
  const disabled = loading || buttonDisabled;

  return (
    <Button
      loading={loading}
      {...rest}
      disabled={disabled}
      style={{
        padding: "unset",
        border: "unset",
        borderRadius: "20px",
        background: "transparent",
      }}
      className={clsx(
        `${hover !== false && "opacity-90 hover:opacity-100"}`,
        "w-full rounded-lg",
        `${disabled && "btn-disabled"}`,
        "disabled:cursor-not-allowed"
      )}
    >
      <div className={clsx("pb-1 rounded-lg", `art-btn-${color}-container`)}>
        <div
          className={clsx(
            "py-3 pt-4 rounded-lg main-font-light text-white justify-content",
            className,
            `art-btn-${color}-content`
          )}
          style={{
            minHeight: "2.5rem",
          }}
        >
          {!loading && children}
        </div>
      </div>
    </Button>
  );
}
