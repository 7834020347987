import { useState } from 'react';
import { Button } from 'semantic-ui-react';

const AutoLoadingButton = ({ onClick, ...rest }: any) => {
    const [isLoading, setLoading] = useState(false);

    const handleClick = async () => {
        setLoading(true);

        if (onClick) {
            await onClick();
        }

        setLoading(false);
    };

    return (
        <Button onClick={handleClick} {...rest} loading={isLoading} >
            {rest.children}
        </Button>
    );
};

export default AutoLoadingButton;