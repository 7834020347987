import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  meta: {},
  contract: {},
  loadingSpine: true,
};
const todosSlice = createSlice({
  name: "sso-meta",
  initialState,
  reducers: {
    removeSSOMeta(state: any, action) {
      let tmp = { ...(state || {}) };
      tmp[action.payload.type] = {};
      return tmp;
    },
    clearSSOMeta() {
      return initialState;
    },
    setSSOMeta(state: any, action) {
      let tmp = { ...(state || {}) };
      for (var i in action.payload) {
        tmp[i] = action.payload[i];
      }
      return tmp;
    },
    setLoadingSpine(state: any, action) {
      let { loading } = action.payload;
      console.log("set loading to ", loading);
      state.loadingSpine = loading;
    },
  },
});

export const { removeSSOMeta, setSSOMeta, clearSSOMeta, setLoadingSpine } =
  todosSlice.actions;

export default todosSlice.reducer;
